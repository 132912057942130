<template>
  <div class="fields form-group pb-0">
    <span class="fieldInputsFiliationImage">
      <!-- PAID OUT SELECT TYPE -->
      <div class="w-100 pointer" @click="$refs.fileInput.click()">
        <input
          type="text"
          class="text-muted h-100 w-100 ps-4 pointer"
          :value="txtFileInput"
          disabled />
      </div>
      <!-- PAID OUT SELECT TYPE -->
      <!-- PAID OUT BUTTON ADD FILE -->
      <template v-if="filesLength > 1">
        <button type="button" class="btn-file" @click="$refs.fileInput.click()">
          <font-awesome-icon
            class="multiFileCircle fs-5"
            :class="{ 'text-success': uploadData.files.length > 0 }"
            :icon="['fas', uploadData.files ? 'circle' : 'paperclip']" />
          <span class="multiFileNumber">{{ filesLength }}</span>
        </button>
      </template>
      <button
        v-else
        type="button"
        class="btn-file"
        @click="$refs.fileInput.click()">
        <font-awesome-icon
          class="fs-5"
          :icon="[
            'fas',
            uploadData.files.length > 0 ? 'check-circle' : 'paperclip',
          ]"
          :class="{ 'text-success': uploadData.files.length > 0 }" />
      </button>
      <!-- PAID OUT BUTTON ADD FILE /-->
      <!-- PAID OUT BUTTON REMOVE FILE -->
      <button
        type="button"
        v-show="uploadData.files.length > 0"
        class="btn-file"
        @click="removeImage">
        <font-awesome-icon class="fs-5" :icon="['fas', 'trash-alt']" />
      </button>
      <!-- PAID OUT BUTTON REMOVE FILE /-->
    </span>
    <small id="uploadHelp" class="form-text text-muted"
      >Archivos permitidos imágenes y pdf.</small
    >
    <input
      type="file"
      ref="fileInput"
      class="d-none"
      accept="image/*, .pdf"
      @change="previewFiles"
      :multiple="multiple"
      single />
  </div>
</template>

<script>
export default {
  name: "UploadContent",
  props: {
    multiple: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data() {
    return {
      filesLength: 0,
      uploadData: {
        files: [],
      },
    };
  },
  computed: {
    txtFileInput() {
      if (this.filesLength > 1) {
        return `${this.filesLength} archivos seleccionados`;
      }
      if (this.filesLength === 1) {
        return `${this.filesLength} archivo seleccionado`;
      }
      return "Seleccionar archivo";
    },
  },
  methods: {
    removeImage() {
      this.uploadData.files = "";
      this.$refs.fileInput.value = "";
      this.filesLength = 0;
      this.$emit("removeFiles");
    },
    previewFiles() {
      this.previewImagesActive = true;
      const arrayFiles = [];
      const { files } = this.$refs.fileInput;
      this.filesLength = Object.values(files).length;
      Object.values(files).forEach((file) => {
        arrayFiles.push(file);
      });
      this.uploadData.files = arrayFiles;
      this.$emit("changeFiles");
    },
  },
};
</script>

<style></style>
